<template>
  <input
    class="form-control form-control-solid me-3"
    placeholder="Search for an artist..."
    v-model="searchQuery"
    v-on:keyup.enter="searchArtist"
  />
  <button
    :disabled="
      searchQuery === '' ||
      ($route.name === 'discover' && !isUserAuthenticatedBySpotify)
    "
    class="btn btn-sm btn-primary me-3"
    @click="searchArtist()"
  >
    Discover
  </button>
</template>
<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { getAuth } from "firebase/auth";

export default {
  setup() {
    const store = useStore();
    const storeSearchQuery = computed(() => {
      return store.getters["EventsModule/getArtistSearchQuery"];
    });
    const searchQuery = ref(storeSearchQuery.value);

    const searchArtist = () => {
      store.dispatch(
        "DiscoverModule/changeSelectedMenu",
        searchQuery.value ? "Search" : "New Releases"
      );
      store.dispatch(
        "EventsModule/searchArtist",
        searchQuery.value ? searchQuery.value : ""
      );
    };

    const isUserAuthenticatedByFirebase = computed(() => {
      return !!getAuth().currentUser;
    });

    const isUserAuthenticatedBySpotify = computed(() => {
      return !!store.getters["SpotifyAccessTokenHelperModule/getAccessToken"];
    });

    watch(
      () => storeSearchQuery.value,
      (currentValue) => {
        searchQuery.value = currentValue;
      }
    );
    return {
      searchQuery,
      searchArtist,
      isUserAuthenticatedByFirebase,
      isUserAuthenticatedBySpotify,
    };
  },
};
</script>

<style lang="scss" scoped>
.advanced-search {
  white-space: nowrap;
}
</style>
