<template>
  <div
    :key="key"
    :class="
      'page d-flex flex-row flex-column-fluid background-fixed background-gradient-' +
      selectedBackgroundClassIndex
    "
  >
    <KTAside
      style="box-shadow: 2px 0 5px -2px #888"
      v-if="isUserAuthenticatedBySpotify"
      :lightLogo="themeLightLogo"
      :darkLogo="themeDarkLogo"
    ></KTAside>
    <div
      id="kt_wrapper"
      :class="[
        { 'p-l-0': !isUserAuthenticatedBySpotify },
        'd-flex flex-column flex-row-fluid wrapper',
      ]"
    >
      <KTHeader
        v-if="isUserAuthenticatedBySpotify"
        :title="pageTitle"
      ></KTHeader>
      <div
        id="kt_content"
        class="pt-3 pb-5 content d-flex flex-column flex-column-fluid"
      >
        <el-row>
          <!--          <el-col :span="1"></el-col>-->
          <el-col :span="24">
            <Discover />
          </el-col>
          <!--          <el-col :span="1"></el-col>-->
        </el-row>
      </div>
    </div>
  </div>
  <KTScrollTop></KTScrollTop>
</template>

<script>
import KTHeader from "./Header.vue";
import KTScrollTop from "./ScrollTop.vue";
import KTAside from "./aside/Aside.vue";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { Actions } from "../store/enums/StoreEnums";
import HtmlClass from "../core/services/LayoutService";
import { themeLightLogo, themeDarkLogo } from "@/core/helpers/config";
import {
  DrawerComponent,
  MenuComponent,
  ScrollComponent,
} from "../assets/ts/components";
import { removeModalBackdrop } from "../core/helpers/dom";
import Discover from "../discover/views/Discover";
export default {
  name: "Layout",
  components: {
    Discover,
    KTHeader,
    KTScrollTop,
    KTAside,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const key = ref(0);
    // const router = useRouter();

    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const selectedBackgroundClassIndex = computed(
      () => store.getters["BackgroundsModule/getSelectedClassIndex"]
    );

    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });

    const isUserAuthenticatedBySpotify = computed(() => {
      return !!store.getters["SpotifyAccessTokenHelperModule/getAccessToken"];
    });

    onMounted(() => {
      DrawerComponent.bootstrap();
      ScrollComponent.bootstrap();
      DrawerComponent.updateAll();
      ScrollComponent.updateAll();

      // Simulate the delay page loading
      setTimeout(() => {
        // Remove page loader after some time
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      }, 500);
    });

    watch(
      () => route.path,
      () => {
        MenuComponent.hideDropdowns(undefined);
        DrawerComponent.hideAll();
        removeModalBackdrop();
        key.value++;
      }
    );

    return {
      key,
      pageTitle,
      breadcrumbs,
      isUserAuthenticatedBySpotify,
      selectedBackgroundClassIndex,
      themeLightLogo,
      themeDarkLogo,
    };
  },

  computed: {
    uuid() {
      return this.$route.params.uuid;
    },
  },
  watch: {
    uuid() {
      this.key++;
    },
  },
};
</script>

<style scoped>
#kt_content {
  margin-top: 6em;
  padding-left: 1.5em;
  padding-right: 1.5em;
}
.p-l-0 {
  padding-left: 0 !important;
}
</style>
