<template>
  <div
    v-loading="loading"
    style="top: 25%; background-color: #e6e6e6"
    class="
      card
      border
      d-flex
      justify-content-center
      align-items-center
      position-sticky
      player
    "
    v-if="(playerData && playerData.artistName) || loading"
  >
    <div class="d-flex justify-content-end mt-2">
      <el-image
        class="icon w-25"
        :src="'/media/svg/social-logos/spotify-text.svg'"
      />
    </div>
    <div
      v-if="playerData && playerData.artistName"
      style="width: 100%"
      class="p-2 mt-1"
    >
      <span class="subheading fw-bolder fs-3">{{ playerData.albumName }}</span
      ><br />
      <span class="subheading">{{ playerData.artistName }}</span>
    </div>
    <div class="card-body p-2">
      <el-image
        style="width: 100%"
        :src="loading ? '/media/avatars/blank.png' : playerData.imageUrl"
        fit="cover"
      ></el-image>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "TrackPlayer",
  setup() {
    const store = useStore();
    const playerData = computed(() => {
      return store.getters["TrackPlaybackModule/getPlayerData"];
    });
    const loading = computed(() => {
      return store.getters["TrackPlaybackModule/getPlayerLoading"];
    });
    return {
      playerData,
      loading,
    };
  },
};
</script>

<style scoped lang="scss">
.ml-25 {
  margin-left: 25px !important;
}
</style>
