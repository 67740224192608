<template>
  <el-row :gutter="20">
    <el-col :xl="18" :lg="17" :md="16">
      <div class="d-flex justify-content-center" :id="id + componentIndex">
        <div class="card flex-grow-1">
          <div class="card-body">
            <el-skeleton :loading="loading" animated>
              <template #default>
                <div class="d-flex justify-content-between">
                  <div>
                    <div class="d-flex">
                      <div>
                        <el-avatar
                          :src="
                            albumData.images.length
                              ? albumData.images[0].url
                              : '/media/avatars/blank.png'
                          "
                          shape="square"
                          :size="100"
                        ></el-avatar>
                      </div>
                      <div class="album-details">
                        <h1 class="truncate">{{ albumData.name }}</h1>

                        <span class="subheading"
                          >Released
                          {{
                            new Date(albumData.release_date).toDateString()
                          }}</span
                        >
                        <div>
                          <span class="subheading">album by </span>
                          <span>{{
                            albumData.artists.length
                              ? albumData.artists[0].name
                              : "N/A"
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="album-tracks">
                    <span class="subheading">TRACKS</span>
                    <div
                      v-for="track in albumData.tracks.items"
                      :key="track.id"
                    >
                      <div class="d-flex align-items-center mt-2">
                        <div>
                          <el-avatar
                            class="cursor-pointer"
                            @click="
                              $emit('add-component', [
                                {
                                  component: 'DiscoverAlbum',
                                  id: track.id,
                                },
                              ])
                            "
                            @mouseenter="
                              playTrack(
                                $event.target.id,
                                track.artists[0].name,
                                track.name,
                                albumData.images[0].url,
                                track.preview_url
                              )
                            "
                            @mouseleave="stopTrack"
                            :id="generateUUID()"
                            shape="square"
                            :src="
                              albumData.images.length
                                ? albumData.images[0].url
                                : '/media/avatars/blank.png'
                            "
                            :size="50"
                          />
                        </div>
                        <span class="subheading truncate album-details">{{
                          track.name
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="right">
                    <div v-if="!loading">
                      <DiscoverActionPanel
                        :button-text="isAlbumSavedByUser ? 'Saved' : 'Save'"
                        :is-followed="isAlbumSavedByUser"
                        :url="albumData.external_urls.spotify"
                        :content="
                          isAlbumSavedByUser
                            ? 'You saved this album'
                            : 'Save this album'
                        "
                        @follow="addToMySavedAlbums"
                        :icon="
                          isAlbumSavedByUser
                            ? '/media/icons/followed.svg'
                            : '/media/icons/save_white.svg'
                        "
                      />
                    </div>
                  </div>
                </div> </template
            ></el-skeleton>
          </div>
        </div>
      </div> </el-col
  ></el-row>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import { useStore } from "vuex";

import useSpotifyApi from "../../common/composables/useSpotifyApi";
import DiscoverActionPanel from "./DiscoverActionPanel";
import { uuid } from "uuidv4";

export default {
  components: { DiscoverActionPanel },
  name: "DiscoverAlbumWithTracks",
  props: {
    id: {
      type: String,
      required: true,
    },
    componentIndex: {
      type: Number,
      required: true,
    },
  },
  methods: {
    generateUUID() {
      return uuid();
    },
  },
  setup(props) {
    const store = useStore();
    const albumData = ref({});
    const isAlbumSavedByUser = ref(false);
    const loading = ref(true);

    onMounted(async () => {
      await checkIsAlbumSaved(props.id);
      await fetchAlbumsData(props.id);
    });

    const addToMySavedAlbums = async () => {
      try {
        const spotifyApi = await useSpotifyApi(store);
        await spotifyApi.addToMySavedAlbums([props.id]);
        isAlbumSavedByUser.value = true;
      } catch (e) {
        console.error(e);
      }
    };

    const checkIsAlbumSaved = async (id) => {
      try {
        loading.value = true;
        const spotifyApi = await useSpotifyApi(store);
        const { body } = await spotifyApi.containsMySavedAlbums([id]);
        isAlbumSavedByUser.value = body[0];
      } catch (e) {
        console.error(e);
      }
    };

    const fetchAlbumsData = async (id) => {
      try {
        const spotifyApi = await useSpotifyApi(store);
        const { body } = await spotifyApi.getAlbum(id);
        albumData.value = body;
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
        store.dispatch(
          "DiscoverModule/scrollToElement",
          props.id + props.componentIndex
        );
      }
    };

    const playTrack = async (
      uuid,
      artist,
      albumName,
      images,
      trackPreviewUrl
    ) => {
      await store.dispatch("TrackPlaybackModule/setTimeOutToPlaySong", () => {
        return {
          uuid,
          artistName: artist,
          albumName,
          imageUrl: images,
          trackPreviewUrl,
        };
      });
    };

    const stopTrack = async () => {
      await store.dispatch("TrackPlaybackModule/stopSong");
    };

    watch(
      () => props.id,
      async (id) => {
        await checkIsAlbumSaved(id);
        await fetchAlbumsData(id);
      }
    );

    return {
      albumData,
      addToMySavedAlbums,
      isAlbumSavedByUser,
      loading,
      playTrack,
      stopTrack,
    };
  },
};
</script>

<style scoped>
.album-tracks {
  max-height: 20rem;
  overflow-y: scroll;
}
.album-details {
  margin-left: 1rem;
}
.truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
