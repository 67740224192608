<template>
  <div id="kt_header" class="header align-items-stretch background-fixed">
    <div
      class="container-fluid d-flex align-items-stretch justify-content-between"
    >
      <div class="d-flex align-items-center w-50">
        <SearchBar />
      </div>
      <div
        class="d-flex align-items-stretch justify-content-end flex-lg-grow-1"
      >
        <div class="d-flex align-items-stretch flex-shrink-0">
          <KTTopbar></KTTopbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KTTopbar from "./TopBar.vue";
import $ from "jquery";
import SearchBar from "../discover/components/SearchBar";

import {
  headerWidthFluid,
  headerLeft,
  asideDisplay,
} from "@/core/helpers/config";
import { computed, onMounted } from "vue";
import { MenuComponent } from "../assets/ts/components";
import { useStore } from "vuex";
export default {
  name: "Header",
  props: {
    title: String,
  },
  components: {
    KTTopbar,
    SearchBar,
  },
  setup() {
    const store = useStore();
    onMounted(() => {
      MenuComponent.reinitialization();
      $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        if (scroll > 0) {
          $("#kt_header").addClass("active");
        } else {
          $("#kt_header").removeClass("active");
        }
      });
    });
    const selectedBackgroundClassIndex = computed(
      () => store.getters["BackgroundsModule/getSelectedClassIndex"]
    );

    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay,
      selectedBackgroundClassIndex,
    };
  },
};
</script>

<style scoped lang="scss">
.l-0 {
  left: 0 !important;
}
.m-r-25 {
  margin-right: 25px;
}
#kt_content {
}
#kt_header {
  .container-fluid {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
  transition: all 0.15s linear;
  &.active {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }
}
</style>
