<template>
  <el-row :gutter="20">
    <el-col :xl="18" :lg="17" :md="16">
      <Playlists
        @addComponent="addComponent"
        :loading="loading"
        card-title="Spotify Playlists"
        card-description="User's spotify playlists"
        :playlist-data="filteredPlaylists"
      />
    </el-col>
  </el-row>
</template>

<script>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";

import useSpotifyApi from "../../common/composables/useSpotifyApi";
import Playlists from "./Playlists";

export default {
  name: "SpotifyPlaylists",
  components: {
    Playlists,
  },
  setup(props, context) {
    const filterQuery = ref("");
    const filteredPlaylists = ref([]);
    const loading = ref(false);
    const playlists = ref([]);
    const store = useStore();

    onMounted(async () => {
      await getCategories();
    });

    const addComponent = (payload) => {
      context.emit("add-component", payload);
    };

    const getCategories = async () => {
      loading.value = true;
      const spotifyApi = await useSpotifyApi(store);
      const { body } = await spotifyApi.getCategories({
        country: "CH",
        limit: 50,
      });
      const categories = body.categories.items.map((category) => {
        return {
          id: category.id,
          name: category.name,
        };
      });
      await getPlaylistsByCategory(categories);
    };

    const getPlaylistsByCategory = async (categories) => {
      let promises = [];
      const spotifyApi = await useSpotifyApi(store);
      categories.forEach((category) => {
        const promise = new Promise((resolve) => {
          spotifyApi
            .getPlaylistsForCategory(category.id, {
              country: "CH",
              limit: 50,
            })
            .then((resp) => {
              if (
                resp.body &&
                resp.body.playlists &&
                resp.body.playlists.items &&
                resp.body.playlists.items.length
              ) {
                playlists.value.push({
                  category: category.name,
                  playlists: resp.body.playlists.items,
                });
              }
              resolve();
            })
            .catch(() => {
              console.log("Unable to find data for: " + category.name);
              resolve();
            });
        });
        promises.push(promise);
      });
      await Promise.all(promises);
      filteredPlaylists.value = [...playlists.value];
      loading.value = false;
    };

    const onFilterPlaylists = () => {
      filteredPlaylists.value = playlists.value.map((item) => {
        const filteredData = item.playlists.filter((playlist) =>
          playlist.name.toLowerCase().includes(filterQuery.value.toLowerCase())
        );
        if (filteredData.length) {
          return {
            category: item.category,
            playlists: filteredData,
          };
        }
      });
    };

    return {
      filterQuery,
      filteredPlaylists,
      loading,
      onFilterPlaylists,
      addComponent,
    };
  },
};
</script>

<style scoped>
.spotify-playlists {
  height: 25rem !important;
  overflow-y: scroll;
}
.playlist-name:hover {
  text-decoration: underline;
}
</style>
