<template>
  <el-row :gutter="20">
    <el-col :xl="18" :lg="17" :md="16">
      <div class="d-flex justify-content-center" :id="id + componentIndex">
        <div class="card flex-grow-1">
          <div class="card-body d-flex justify-content-between">
            <el-skeleton :loading="loading" animated>
              <template #default>
                <div class="w-100 d-flex">
                  <div>
                    <el-avatar
                      class="cursor-pointer"
                      @mouseenter="
                        playTrack(
                          $event.target.id,
                          getArtistName(albumData.artists),
                          albumData.name,
                          albumData.album.images[0].url,
                          albumData.preview_url
                        )
                      "
                      @mouseleave="stopTrack"
                      :id="generateUUID()"
                      fit="fill"
                      shape="square"
                      :size="100"
                      :src="albumData.album.images[0].url"
                    ></el-avatar>
                  </div>
                  <div
                    class="w-100 album-details d-flex justify-content-between"
                  >
                    <div class="w-75">
                      <h3>{{ albumData.name }}</h3>
                      <span>
                        {{
                          `From the ${albumData.album.album_type} ${
                            albumData.album.name
                          } (${new Date(
                            albumData.album.release_date
                          ).getFullYear()}) `
                        }}
                      </span>
                      <div>
                        <span>By {{ " " }}</span>
                        <span
                          v-for="(artist, index) in albumData.album.artists"
                          :key="index"
                          >{{ `${artist.name} ` }}</span
                        >
                      </div>
                    </div>
                    <div>
                      <div v-if="!loading">
                        <DiscoverActionPanel
                          :button-text="isTrackSavedByUser ? 'Saved' : 'Save'"
                          :is-followed="isTrackSavedByUser"
                          :url="albumData.external_urls.spotify"
                          :content="
                            isTrackSavedByUser
                              ? 'You saved this track'
                              : 'Save this track'
                          "
                          @follow="addToMySavedTracks"
                          @open="openPlaylistModal"
                          :icon="
                            isTrackSavedByUser
                              ? '/media/icons/followed.svg'
                              : '/media/icons/save_white.svg'
                          "
                          :show-playlist-button="true"
                        />
                      </div>
                    </div>
                  </div>
                </div> </template
            ></el-skeleton>
          </div>
        </div>
        <AddToPlaylistModal
          ref="addToPlaylistModal"
          @add-track="addTrackToPlaylist"
          @create-playlist="createPlaylist"
        />
        <button
          v-if="!loading"
          class="visually-hidden"
          ref="modalButton"
          data-bs-toggle="modal"
          data-bs-target="#playlist_modal"
        >
          Open Modal
        </button>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import { useStore } from "vuex";

import AddToPlaylistModal from "./AddToPlaylistModal";
import useSpotifyApi from "../../common/composables/useSpotifyApi";
import DiscoverActionPanel from "./DiscoverActionPanel";
import { ElNotification } from "element-plus";
import { uuid } from "uuidv4";

export default {
  name: "DiscoverAlbum",
  components: { AddToPlaylistModal, DiscoverActionPanel },
  props: {
    id: {
      type: String,
      required: true,
    },
    componentIndex: {
      type: Number,
      required: true,
    },
  },
  methods: {
    generateUUID() {
      return uuid();
    },
  },
  setup(props) {
    const addToPlaylistModal = ref(null);
    const modalButton = ref(null);
    const store = useStore();
    const isTrackSavedByUser = ref(false);
    const albumData = ref({});
    const loading = ref(true);

    onMounted(async () => {
      await fetchAlbumData(props.id);
    });

    const playTrack = async (
      uuid,
      artist,
      albumName,
      images,
      trackPreviewUrl
    ) => {
      await store.dispatch("TrackPlaybackModule/setTimeOutToPlaySong", () => {
        return {
          uuid,
          artistName: artist,
          albumName,
          imageUrl: images,
          trackPreviewUrl,
        };
      });
    };

    const stopTrack = async () => {
      await store.dispatch("TrackPlaybackModule/stopSong");
    };

    const getArtistName = (artists) => {
      let artistName = "";
      for (let i = 0; i < artists.length; i++) {
        artistName += artists[i].name;
      }
      return artistName;
    };

    const addTrackToPlaylist = async (playlistId) => {
      try {
        const spotifyApi = await useSpotifyApi(store);
        await spotifyApi.addTracksToPlaylist(playlistId, [
          `spotify:track:${props.id}`,
        ]);
        modalButton.value.click();
        playlistSuccessMessage();
      } catch (e) {
        console.error(e);
        playlistErrorMessage();
      }
    };

    const addToMySavedTracks = async () => {
      try {
        const spotifyApi = await useSpotifyApi(store);
        await spotifyApi.addToMySavedTracks([props.id]);
        isTrackSavedByUser.value = true;
      } catch (e) {
        console.error(e);
      }
    };
    const createPlaylist = async (playlistName) => {
      try {
        const spotifyApi = await useSpotifyApi(store);
        const { body } = await spotifyApi.createPlaylist(playlistName);
        await addTrackToPlaylist(body.id);
      } catch (e) {
        console.error(e);
        playlistErrorMessage();
      }
    };
    const checkIsTrackSavedByUser = async (trackId) => {
      const spotifyApi = await useSpotifyApi(store);
      const { body } = await spotifyApi.containsMySavedTracks([trackId]);
      isTrackSavedByUser.value = body[0];
    };

    const fetchAlbumData = async (id) => {
      try {
        loading.value = true;
        const spotifyApi = await useSpotifyApi(store);
        const { body } = await spotifyApi.getTrack(id);
        await checkIsTrackSavedByUser(id);
        albumData.value = body;
        store.dispatch(
          "DiscoverModule/scrollToElement",
          props.id + props.componentIndex
        );
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    };

    const openPlaylistModal = () => {
      modalButton.value.click();
      addToPlaylistModal.value.fetchUserPlaylists();
    };

    const playlistSuccessMessage = () => {
      ElNotification({
        title: "Success",
        message: "Track added to playlist.",
        type: "success",
      });
    };

    const playlistErrorMessage = () => {
      ElNotification({
        title: "Error",
        message: "Track not added",
        type: "error",
      });
    };

    watch(
      () => props.id,
      async (id) => {
        await fetchAlbumData(id);
      }
    );

    return {
      addToPlaylistModal,
      albumData,
      addToMySavedTracks,
      addTrackToPlaylist,
      createPlaylist,
      isTrackSavedByUser,
      loading,
      modalButton,
      openPlaylistModal,
      playTrack,
      stopTrack,
      getArtistName,
    };
  },
};
</script>

<style scoped>
.album-details {
  margin-left: 1rem;
}
</style>
