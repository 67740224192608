<template>
  <CardToolbar
    v-if="!accessToken"
    title=""
    description=""
    :show-toolbar-buttons="false"
    class="pb-9 background-transparent"
  >
    <template v-slot:body>
      <div class="mb-5 text-center">
        <h6 class="subheading fw-light mb-3 text-white">
          You are not logged in to Spotify, Please login
        </h6>
        <a class="btn btn-sm btn-success me-3" @click="loginToSpotify"
          >Login to Spotify</a
        >
      </div>
    </template>
  </CardToolbar>
  <div v-if="accessToken">
    <WelcomeBanner v-if="currentBannerState" />
    <keep-alive>
      <component
        class="min-height-430"
        @mouseleave="hideTrackComponent"
        v-bind:is="componentName"
        v-bind="currentProps"
        @add-component="addComponentToView($event, 0)"
      />
    </keep-alive>
    <component
      @mouseleave="hideTrackComponent"
      @add-component="addComponentToView($event, index + 1)"
      class="mt-5"
      v-for="(component, index) in components"
      :key="index"
      :is="component.componentName"
      v-bind:class="component.id"
      :ref="component.id"
      v-bind="component.currentProps"
    />
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useStore } from "vuex";

import CardToolbar from "../../common/components/CardToolbar";
import DiscoverArtistDetail from "../components/DiscoverArtistDetail";
import DiscoverPlaylist from "../components/DiscoverPlaylist";
import DiscoverAlbum from "../components/DiscoverAlbum";
import DiscoverAlbumWithTracks from "../components/DiscoverAlbumWithTracks";
import Search from "../components/Search";
import Genres from "../components/Genres";
import RandomArtist from "../components/RandomArtist";
import SpotifyPlaylists from "../components/SpotifyPlaylists";
import MyPlaylists from "../components/MyPlaylists";
import NewReleases from "../components/NewReleases";
import MyAlbums from "../components/MyAlbums";
import WelcomeBanner from "../components/WelcomeBanner";

export default {
  name: "Discover",
  components: {
    CardToolbar,
    DiscoverArtistDetail,
    DiscoverAlbum,
    DiscoverPlaylist,
    DiscoverAlbumWithTracks,
    Genres,
    RandomArtist,
    Search,
    SpotifyPlaylists,
    MyPlaylists,
    NewReleases,
    MyAlbums,
    WelcomeBanner,
  },
  setup() {
    const components = ref([]);
    const store = useStore();
    const currentlySelectedMenu = computed(
      () => store.getters["DiscoverModule/getCurrentlySelectedMenu"]
    );
    const componentName = computed(() =>
      currentlySelectedMenu.value.replace(/\s/g, "")
    );
    const currentBannerState = computed(
      () => store.getters["DiscoverModule/getBannerState"]
    );
    const searchQueryDiscoverTool = computed(() => {
      return store.getters["EventsModule/getArtistSearchQuery"];
    });
    const currentProps = computed(
      () =>
        componentName.value === "Search" && {
          query: searchQueryDiscoverTool.value,
        }
    );
    const spotifyUser = computed(
      () => store.getters["DiscoverModule/getSpotifyUser"]
    );
    const accessToken = computed(
      () => store.getters["SpotifyAccessTokenHelperModule/getAccessToken"]
    );

    const addComponentToView = (componentData, componentIndex) => {
      if (componentIndex === 0) {
        components.value = [];
      } else if (componentIndex < components.value.length) {
        components.value.splice(componentIndex);
      }
      componentData.forEach((data) => {
        components.value.push({
          componentName: data.component,
          currentProps: {
            id: data.id,
            index: components.value.length,
            componentIndex,
          },
          id: `${data.component}${components.value.length}`,
        });
      });
    };

    const generateRandomString = function (length) {
      let text = "";
      const possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }
      return text;
    };

    const hideTrackComponent = async () => {
      await store.dispatch("TrackPlaybackModule/stopSong");
    };

    const loginToSpotify = () => {
      const clientId = process.env.VUE_APP_SPOTIFY_CLIENT_ID;
      const response_type = "code";
      const redirectUri = process.env.VUE_APP_REDIRECT_URI;
      const state = generateRandomString(16);
      const scope =
        "user-read-private user-read-email user-follow-modify user-follow-read user-library-modify user-library-read playlist-modify-private playlist-read-private playlist-modify-public";
      window.location.href = `https://accounts.spotify.com/authorize?response_type=${response_type}&client_id=${clientId}&scope=${scope}&redirect_uri=${redirectUri}&state=${state}`;
    };

    onMounted(async () => {
      await store.dispatch("TrackPlaybackModule/createPlayer");
    });

    onUnmounted(() => {
      store.commit("EventsModule/CLEAR_SEARCH_QUERY");
    });

    watch(
      () => searchQueryDiscoverTool.value,
      () => {
        components.value = [];
      }
    );

    watch(
      () => currentlySelectedMenu.value,
      () => {
        components.value = [];
      }
    );
    return {
      accessToken,
      addComponentToView,
      currentlySelectedMenu,
      componentName,
      currentProps,
      components,
      currentBannerState,
      hideTrackComponent,
      loginToSpotify,
      spotifyUser,
    };
  },
};
</script>

<style scoped lang="scss">
.display-none {
  display: none !important;
}
.pr-40 {
  padding-right: 40px;
}
.min-height-430 {
  min-height: 430px;
}
.p-l-0 {
  padding-left: 0 !important;
}
.p-r-0 {
  padding-right: 0 !important;
}
</style>
