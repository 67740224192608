<template>
  <div class="card mb-3">
    <div v-if="title" class="card-header border-0 pt-5">
      <div>
        <h2 class="heading">{{ title }}</h2>
        <p class="subheading">
          {{ description }}
        </p>
      </div>
      <div
        class="card-toolbar"
        data-kt-buttons="true"
        v-if="showToolbarButtons"
      >
        <slot name="toolbar">
          <a
            class="
              btn btn-sm btn-color-muted btn-active btn-active-primary
              px-4
              me-1
            "
            :class="[{ active: days === 7 }]"
            @click="changeDaysValue(7)"
            >Last 7 days</a
          >

          <a
            class="
              btn btn-sm btn-color-muted btn-active btn-active-primary
              px-4
              me-1
            "
            :class="[{ active: days === 14 }]"
            @click="changeDaysValue(14)"
            >Last 14 days</a
          >

          <a
            class="
              btn btn-sm btn-color-muted btn-active btn-active-primary
              px-4
            "
            @click="changeDaysValue(28)"
            :class="[{ active: days === 28 }]"
            >Last 28 days</a
          >

          <a
            class="
              btn btn-sm btn-color-muted btn-active btn-active-primary
              px-4
            "
            :class="[{ active: days === 365 }]"
            @click="changeDaysValue(365)"
            >Last 12 Months</a
          ></slot
        >
      </div>
    </div>
    <div class="card-body pt-9 pb-0">
      <slot name="body" :days="days"></slot>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "CardToolbar",
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    showToolbarButtons: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const days = ref(365);

    const changeDaysValue = (day) => {
      days.value = day;
      context.emit("daysChanged", days.value);
    };

    return { days, changeDaysValue };
  },
};
</script>

<style lang="scss" scoped></style>
