<template>
  <el-row :gutter="20">
    <el-col :xl="18" :lg="17" :md="16">
      <div class="card mb-8">
        <div class="card-body">
          <span
            @click="hideBanner"
            class="
              svg-icon svg-icon-1
              p-2
              btn btn-md btn-icon btn-active-color-primary
              close-icon
            "
          >
            <inline-svg
              class="svg-icon"
              src="media/icons/duotune/arrows/arr061.svg"
            />
          </span>
          <div class="text-center">
            <h1 class="heading">Welcome to SoundCode</h1>
            <h3 class="description">This is how it works...</h3>
          </div>
          <div class="d-flex justify-content-center pt-5">
            <div class="icon-box text-center flex-grow-1 cursor-pointer">
              <img class="w-40px h-40px" src="/media/icons/hover.svg" />
              <h1 class="description">Hover</h1>
              <h4 class="heading">to listen</h4>
            </div>
            <div class="icon-box text-center flex-grow-1 cursor-pointer">
              <img class="w-40px h-40px" src="/media/icons/click.svg" />
              <h1 class="description">Click</h1>
              <h4 class="heading">to surf deeper</h4>
            </div>
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import { useStore } from "vuex";

export default {
  name: "WelcomeBanner",
  setup() {
    const store = useStore();

    const hideBanner = () => {
      store.dispatch("DiscoverModule/hideBanner");
    };

    return { hideBanner };
  },
};
</script>

<style scoped>
.description {
  color: #144f7d;
}
.icon-box {
  box-shadow: -1px 11px 32px -13px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  max-width: 25%;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}
.close-icon {
  position: absolute;
  right: 0.1rem;
  top: 0.1rem;
}
</style>
