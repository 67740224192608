
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "kt-user-menu",
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();

    const isUserAuthenticatedBySpotify = computed(() => {
      return !!store.getters["SpotifyAccessTokenHelperModule/getAccessToken"];
    });

    const selectedBackgroundClassIndex = computed(
      () => store.getters["BackgroundsModule/getSelectedClassIndex"]
    );

    const spotifyUser = computed(() => {
      return store.getters["SpotifyAccessTokenHelperModule/getSpotifyUser"];
    });

    const setSelectedBackgroundColor = (index) => {
      store.dispatch("BackgroundsModule/setSelectedClassIndex", index);
    };

    const logout = async () => {
      await store.dispatch("UserModule/logoutUser");
      await store.dispatch("DiscoverModule/resetStore");
      await store.dispatch("EventsModule/resetStore");
      await store.dispatch("SpotifyAccessTokenHelperModule/resetStore");
      await router.push("/");
    };
    return {
      logout,
      spotifyUser,
      setSelectedBackgroundColor,
      selectedBackgroundClassIndex,
      isUserAuthenticatedBySpotify,
    };
  },
});
