<template>
  <div
    class="d-flex cursor-pointer align-items-center mt-4 spotifySearchResult"
    @mouseenter="onHoverEnter"
    @mouseleave="onHoverLeave"
  >
    <div>
      <el-avatar :shape="imageShape" :size="50" :src="imageUrl"></el-avatar>
    </div>
    <div class="track-details">
      <span class="d-block fw-bold">{{ name }}</span>
      <span class="subheading" v-for="(n, index) in 2" :key="index">
        <span v-if="artistNames[index]">{{ artistNames[index].name }}</span>
      </span>
      <span v-if="artistNames.length > 2">. . .</span>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["onHoverEnter", "onHoverLeave"],
  name: "SpotifySearchResult",
  props: {
    name: {
      type: String,
      required: true,
    },
    artistNames: {
      type: Array,
      default() {
        return [];
      },
    },
    imageUrl: {
      type: String,
      default: "/media/avatars/blank.png",
    },
    imageShape: {
      type: String,
      default: "square",
    },
  },
  setup(props, context) {
    const onHoverEnter = async (event) => {
      await context.emit("onHoverEnter", event);
    };
    const onHoverLeave = async (event) => {
      await context.emit("onHoverLeave", event);
    };
    return { onHoverEnter, onHoverLeave };
  },
};
</script>

<style scoped>
.spotifySearchResult {
  /*width: fit-content;*/
  height: fit-content;
}
.track-details {
  margin-left: 1rem;
}
</style>
