<template>
  <el-row :gutter="20">
    <el-col :xl="18" :lg="17" :md="16">
      <Playlists
        @addComponent="addComponent"
        :loading="loading"
        card-title="My Playlists"
        card-description="Playlists owned or followed by you"
        :playlist-data="playlistWithCategories"
      /> </el-col
  ></el-row>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";

import useSpotifyApi from "../../common/composables/useSpotifyApi";
import Playlists from "./Playlists";
export default {
  name: "MyPlaylists",
  components: { Playlists },
  setup(props, context) {
    const loading = ref(false);
    const playlists = ref([]);
    const playlistWithCategories = ref([]);
    const store = useStore();
    const user = computed(
      () => store.getters["SpotifyAccessTokenHelperModule/getSpotifyUser"]
    );

    onMounted(async () => {
      await getUserPlaylists();
    });

    const addComponent = (payload) => {
      context.emit("add-component", payload);
    };

    const dividePlaylistIntoCategories = (playlists) => {
      const categoriesMap = new Map();
      categoriesMap.set("All", []);
      categoriesMap.set("Playlists You Own", []);
      categoriesMap.set("Playlists You Follow", []);
      playlists.forEach((playlist) => {
        if (playlist.owner.id === user.value.id) {
          const playlistsYouOwn = categoriesMap.get("Playlists You Own");
          playlistsYouOwn.push(playlist);
          categoriesMap.set("Playlists You Own", playlistsYouOwn);
        } else {
          const playlistsYouFollow = categoriesMap.get("Playlists You Follow");
          playlistsYouFollow.push(playlist);
          categoriesMap.set("Playlists You Follow", playlistsYouFollow);
        }
        const AllPlaylists = categoriesMap.get("All");
        AllPlaylists.push(playlist);
        categoriesMap.set("All", AllPlaylists);
      });
      categoriesMap.forEach((value, key) => {
        playlistWithCategories.value.push({
          category: key,
          playlists: value,
        });
      });
      loading.value = false;
    };

    const getUserPlaylists = async () => {
      loading.value = true;
      const spotifyApi = await useSpotifyApi(store);
      const limit = 50;
      const { body } = await spotifyApi.getUserPlaylists(user.value.id, {
        limit,
      });
      playlists.value = body.items;
      if (body.total > limit) {
        await getPaginatedData(limit, body.total);
      } else {
        loading.value = false;
      }
    };

    const getPaginatedData = async (limit, total) => {
      const spotifyApi = await useSpotifyApi(store);
      let promises = [];
      let remainingEntries = total - limit;
      let offset = limit;
      while (remainingEntries > 0) {
        const promise = spotifyApi.getUserPlaylists(user.value.id, {
          offset,
          limit: 50,
        });
        promises.push(promise);
        offset += limit;
        remainingEntries -= limit;
      }
      const remainingPlaylists = await Promise.all(promises);
      remainingPlaylists.forEach((res) => {
        playlists.value = [...playlists.value, res.body.items];
      });
      playlists.value = playlists.value.flat();
      dividePlaylistIntoCategories(playlists.value);
    };

    return { loading, playlistWithCategories, addComponent };
  },
};
</script>

<style scoped></style>
