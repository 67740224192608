<template>
  <div class="d-flex align-items-stretch flex-shrink-0">
    <div class="d-flex align-items-center ms-1 ms-lg-3"></div>
    <div
      class="d-flex align-items-center ms-1 ms-lg-3"
      id="kt_header_user_menu_toggle"
    >
      <div
        class="cursor-pointer symbol symbol-30px symbol-md-40px"
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="bottom"
      >
        <img
          v-if="isUserAuthenticatedBySpotify && spotifyUser"
          alt="Logo"
          :src="
            spotifyUser.images && spotifyUser.images[0]
              ? spotifyUser.images[0].url
              : '/media/avatars/150-26.jpg'
          "
        />
      </div>
      <KTUserMenu></KTUserMenu>
    </div>
    <div
      class="d-flex align-items-center d-lg-none ms-2 me-n3"
      title="Show header menu"
    >
      <div
        class="btn btn-icon btn-active-light-primary"
        id="kt_header_menu_mobile_toggle"
      >
        <span class="svg-icon svg-icon-1">
          <inline-svg src="/media/icons/duotune/text/txt001.svg" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import KTUserMenu from "./UserMenu.vue";
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  name: "TopBar",
  components: {
    // KTNotificationsMenu,
    KTUserMenu,
  },
  setup() {
    const store = useStore();

    const isUserAuthenticatedBySpotify = computed(() => {
      return !!store.getters["SpotifyAccessTokenHelperModule/getAccessToken"];
    });

    const spotifyUser = computed(() => {
      return store.getters["SpotifyAccessTokenHelperModule/getSpotifyUser"];
    });

    return {
      spotifyUser,
      isUserAuthenticatedBySpotify,
    };
  },
};
</script>

<style scoped></style>
