<template>
  <div class="d-flex">
    <el-tooltip :content="content" placement="top-start">
      <Button
        v-loading="loading"
        class="btn btn-sm btn-primary me-3"
        v-on="isFollowed === false ? { click: follow } : {}"
      >
        {{ buttonText }}
      </Button></el-tooltip
    >
    <el-tooltip
      v-if="showPlaylistButton"
      content="Add to Playlist"
      placement="top-start"
    >
      <Button class="btn btn-sm btn-primary me-3" @click="showPlaylistModal">
        Add
      </Button>
    </el-tooltip>
    <el-tooltip content="Open in Spotify" placement="top-start">
      <Button class="btn btn-sm btn-primary me-3" @click="redirectToSpotify">
        Open</Button
      ></el-tooltip
    >
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "DiscoverActionPanel",
  props: {
    content: {
      type: String,
      required: true,
    },
    isFollowed: {
      type: Boolean,
      required: true,
    },
    buttonText: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      default: process.env.VUE_APP_API_URL,
    },
    showPlaylistButton: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const addToPlaylistModal = ref(null);
    const loading = ref(false);

    const follow = async () => {
      context.emit("follow");
    };

    const redirectToSpotify = () => {
      window.open(props.url, "_blank");
    };

    const showPlaylistModal = async () => {
      context.emit("open");
    };

    return {
      addToPlaylistModal,
      follow,
      loading,
      redirectToSpotify,
      showPlaylistModal,
    };
  },
};
</script>

<style scoped lang="scss"></style>
