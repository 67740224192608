<template>
  <CardToolbar :description="cardDescription" :title="cardTitle">
    <template v-slot:toolbar>
      <input
        class="form-control form-control-solid me-3"
        placeholder="Filter"
        v-model="filterQuery"
        @keypress.enter="onFilterPlaylists"
      />
    </template>
    <template v-slot:body>
      <el-skeleton :loading="loading" animated>
        <template #default>
          <div class="spotify-playlists">
            <div
              class="mb-5"
              v-for="(item, index) in filteredPlaylists"
              :key="index"
            >
              <h6>{{ item.category }}</h6>
              <el-row :gutter="5">
                <el-col
                  v-for="(playlist, index) in item.playlists"
                  :key="index"
                  :span="8"
                >
                  <span
                    class="fw-light playlist-name cursor-pointer"
                    @click="
                      $emit('add-component', [
                        {
                          component: 'DiscoverPlaylist',
                          id: playlist.id,
                        },
                      ])
                    "
                    >{{ playlist.name }}</span
                  >
                </el-col>
              </el-row>
            </div>
          </div>
        </template>
      </el-skeleton>
    </template>
  </CardToolbar>
</template>

<script>
import { ref, watch } from "vue";
import CardToolbar from "../../common/components/CardToolbar";
export default {
  components: { CardToolbar },
  name: "Playlists",
  props: {
    cardTitle: {
      type: String,
      required: true,
    },
    cardDescription: {
      type: String,
      required: true,
    },
    playlistData: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const filteredPlaylists = ref(props.playlistData);
    const filterQuery = ref("");

    const onFilterPlaylists = () => {
      filteredPlaylists.value = props.playlistData
        .map((item) => {
          const filteredData = item.playlists.filter((playlist) =>
            playlist.name
              .toLowerCase()
              .includes(filterQuery.value.toLowerCase())
          );
          if (filteredData.length) {
            return {
              category: item.category,
              playlists: filteredData,
            };
          }
        })
        .filter((result) => result);
    };

    watch(
      () => props.playlistData,
      (playlistData) => {
        filteredPlaylists.value = playlistData;
      }
    );

    return { filterQuery, filteredPlaylists, onFilterPlaylists };
  },
};
</script>

<style scoped>
.spotify-playlists {
  height: 25rem !important;
  overflow-y: scroll;
}
.playlist-name:hover {
  text-decoration: underline;
}
</style>
