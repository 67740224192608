<template>
  <el-row :gutter="20">
    <el-col :xl="18" :lg="17" :md="16">
      <div class="d-flex justify-content-center" :id="id + componentIndex">
        <div class="card flex-grow-1">
          <div class="card-body">
            <el-skeleton :loading="loading" animated>
              <template #default>
                <div class="d-flex justify-content-between">
                  <div class="d-flex w-75">
                    <div>
                      <el-avatar
                        class="cursor-pointer"
                        fit="fill"
                        shape="square"
                        :size="120"
                        :src="
                          playlistData.images.length
                            ? playlistData.images[0].url
                            : '/media/avatars/blank.png'
                        "
                      />
                    </div>
                    <div
                      class="
                        mx-5
                        d-flex
                        flex-column
                        artist-details
                        justify-content-center
                      "
                    >
                      <h3>{{ playlistData.name }}</h3>
                      <span v-html="playlistData.description"></span>
                    </div>
                  </div>
                  <div>
                    <div>
                      <DiscoverActionPanel
                        v-if="!loading"
                        :button-text="
                          isPlaylistFollowedByUser ? 'Following' : 'Follow'
                        "
                        :content="
                          isPlaylistFollowedByUser
                            ? 'You follow this playlist'
                            : 'Follow this playlist'
                        "
                        :is-followed="isPlaylistFollowedByUser"
                        :url="playlistData.externalUrl"
                        @follow="followPlaylist"
                        :icon="
                          isPlaylistFollowedByUser
                            ? '/media/icons/followed.svg'
                            : '/media/icons/add_to_playlist_white.svg'
                        "
                      />
                    </div>
                  </div>
                </div>
                <div class="mt-5">
                  <el-avatar
                    :id="generateUUID()"
                    @mouseenter="playSong($event.target.id, track)"
                    @mouseleave="stopSong"
                    class="playlist-description cursor-pointer"
                    @click="addComponentToView(track)"
                    :size="50"
                    v-for="(track, index) in playlistTracks"
                    :key="index"
                    fit="fill"
                    shape="square"
                    :src="
                      track.track && track.track.album.images.length
                        ? track.track.album.images[0].url
                        : '/media/avatars/blank.png'
                    "
                  ></el-avatar>
                </div> </template
            ></el-skeleton>
          </div>
        </div>
      </div> </el-col
  ></el-row>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { uuid } from "uuidv4";

import useSpotifyApi from "../../common/composables/useSpotifyApi";
import DiscoverActionPanel from "./DiscoverActionPanel";
export default {
  name: "DiscoverPlaylist",
  components: { DiscoverActionPanel },
  props: {
    id: {
      type: String,
      required: true,
    },
    componentIndex: {
      type: Number,
      required: true,
    },
  },
  setup(props, context) {
    const isPlaylistFollowedByUser = ref(false);
    const loading = ref(true);
    const playlistData = ref({});
    const playlistTracks = ref([]);
    const store = useStore();

    onMounted(async () => {
      await getPlaylist();
    });

    const addComponentToView = async (track) => {
      context.emit("add-component", [
        {
          component: "DiscoverAlbum",
          id: track.track.id,
        },
        {
          component: "DiscoverArtistDetail",
          id: track.track.artists[0].id,
        },
      ]);
    };

    const checkIsPlaylistFollowedByUser = async (playlistId) => {
      try {
        const spotifyApi = await useSpotifyApi(store);
        const currentUser = await spotifyApi.getMe();
        const { body } = await spotifyApi.areFollowingPlaylist(
          playlistData.value.ownerId,
          playlistId,
          [currentUser.body.id]
        );
        isPlaylistFollowedByUser.value = body[0];
      } catch (e) {
        console.error(e);
      }
    };

    const followPlaylist = async () => {
      try {
        const spotifyApi = await useSpotifyApi(store);
        const { body } = await spotifyApi.followPlaylist(props.id);
        isPlaylistFollowedByUser.value = true;
      } catch (e) {
        console.error(e);
      }
    };

    const getPlaylist = async (id = props.id) => {
      try {
        loading.value = true;
        const spotifyApi = await useSpotifyApi(store);
        const { body } = await spotifyApi.getPlaylist(id);
        playlistData.value = {
          name: body.name,
          ownerId: body.owner.id,
          description: body.description,
          images: body.images,
          id: body.id,
          externalUrl: body.external_urls.spotify,
        };
        playlistTracks.value = body.tracks.items.length
          ? body.tracks.items
          : [];
        await checkIsPlaylistFollowedByUser(id);
      } catch (e) {
        console.error(e);
      } finally {
        store.dispatch(
          "DiscoverModule/scrollToElement",
          props.id + props.componentIndex
        );
        loading.value = false;
      }
    };

    const playSong = async (uuid, track) => {
      await store.dispatch(
        "TrackPlaybackModule/setTimeOutToPlaySong",
        async () => {
          const trackPreviewUrl = track.track.preview_url;
          const imageUrl =
            track.track.album && track.track.album.images[0]
              ? track.track.album.images[0].url
              : "/media/avatars/blank.png";
          const albumName = track.track.album.name;
          const artistName = getArtistName(track.track.artists);
          return {
            uuid,
            artistName,
            albumName,
            imageUrl,
            trackPreviewUrl,
          };
        }
      );
    };

    const stopSong = async () => {
      await store.dispatch("TrackPlaybackModule/stopSong");
    };

    const getArtistName = (artist) => {
      let artistName = "";
      for (let i = 0; i < artist.length; i++) {
        artistName += artist[i].name;
      }
      return artistName;
    };

    watch(
      () => props.id,
      async (id) => {
        await getPlaylist(id);
      }
    );

    return {
      addComponentToView,
      followPlaylist,
      isPlaylistFollowedByUser,
      loading,
      playlistData,
      playlistTracks,
      playSong,
      stopSong,
    };
  },
  methods: {
    generateUUID() {
      return uuid();
    },
  },
};
</script>

<style scoped>
.playlist-description {
  margin-left: 0.3rem;
}
</style>
