import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  id: "kt_aside_menu_wrapper",
  ref: "scrollElRef",
  class: "hover-scroll-overlay-y mt-5 d-flex flex-column justify-content-between",
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "{default: false, lg: true}",
  "data-kt-scroll-dependencies": "#kt_aside_logo, #kt_aside_footer",
  "data-kt-scroll-height": "auto",
  "data-kt-scroll-offset": "0",
  "data-kt-scroll-wrappers": "#kt_aside_menu"
}
const _hoisted_2 = {
  id: "#kt_header_menu",
  class: "menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500",
  "data-kt-menu": "true"
}
const _hoisted_3 = {
  key: 0,
  class: "menu-item"
}
const _hoisted_4 = { class: "menu-content pb-2" }
const _hoisted_5 = { class: "menu-section text-muted text-uppercase fs-8 ls-1" }
const _hoisted_6 = {
  key: 0,
  class: "menu-item"
}
const _hoisted_7 = {
  key: 0,
  class: "menu-icon"
}
const _hoisted_8 = {
  key: 1,
  class: "svg-icon svg-icon-2"
}
const _hoisted_9 = { class: "menu-title" }
const _hoisted_10 = { class: "position-sticky" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_TrackPlayer = _resolveComponent("TrackPlayer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.MainMenuConfig, (item, i) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
          (item.heading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.translate(item.heading)), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.pages, (menuItem, j) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: j }, [
              (menuItem.name)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createVNode(_component_router_link, {
                      to: "/",
                      disabled: menuItem.disabled,
                      onClick: ($event: any) => (_ctx.handleClick(menuItem))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("a", {
                          class: _normalizeClass([[menuItem.name === _ctx.selectedMenu && 'active'], "menu-link"])
                        }, [
                          (menuItem.svgIcon || menuItem.fontIcon)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                                (_ctx.asideMenuIcons === 'font')
                                  ? (_openBlock(), _createElementBlock("i", {
                                      key: 0,
                                      class: _normalizeClass([menuItem.fontIcon, "bi fs-3"])
                                    }, null, 2))
                                  : (_ctx.asideMenuIcons === 'svg')
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                                        _createVNode(_component_inline_svg, {
                                          src: menuItem.svgIcon
                                        }, null, 8, ["src"])
                                      ]))
                                    : _createCommentVNode("", true)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.translate(menuItem.name)), 1)
                        ], 2)
                      ]),
                      _: 2
                    }, 1032, ["disabled", "onClick"])
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ], 64))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(_component_TrackPlayer)
    ])
  ], 512))
}