const DocMenuConfig = [
  {
    heading: "Discover",
    pages: [
      {
        name: "New Releases",
        disabled: false,
        svgIcon: "/media/icons/duotune/files/fil021.svg",
        fontIcon: "bi-layers",
      },
      {
        name: "Genres",
        disabled: false,
        svgIcon: "/media/icons/duotune/maps/map006.svg",
        fontIcon: "bi-layers",
      },
      {
        name: "Spotify Playlists",
        disabled: false,
        svgIcon: "/media/icons/duotune/layouts/lay002.svg",
        fontIcon: "bi-layers",
      },
      {
        name: "Mood EQ (coming soon)",
        disabled: true,
        svgIcon: "/media/icons/duotune/medicine/med001.svg",
        fontIcon: "bi-layers",
      },
      {
        name: "Shazams (coming soon)",
        disabled: true,
        svgIcon: "/media/icons/duotune/general/gen004.svg",
        fontIcon: "bi-layers",
      },
    ],
  },
  {
    heading: "Library",
    pages: [
      {
        name: "My Playlists",
        disabled: false,
        svgIcon: "/media/icons/duotune/general/gen029.svg",
        fontIcon: "bi-layers",
      },
      {
        name: "My Albums",
        disabled: false,
        svgIcon: "/media/icons/duotune/general/gen025.svg",
        fontIcon: "bi-layers",
      },
    ],
  },
];

export default DocMenuConfig;
